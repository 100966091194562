import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  async get(payload, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    return $backend
      .post("teachers_get_course_grading", payload)
      .then(checkStatus);
  },

  async save(payload, headers = {}) {
    return $backend
      .post("teachers_set_course_grading", payload)
      .then(checkStatus);
  },

  async calculate(payload, headers = {}) {
    return $backend
      .post("teachers_calculate_batch_result", payload)
      .then(checkStatus);
  },

  async publish(payload, headers = {}) {
    return $backend
      .post("teachers_publish_batch_result", payload)
      .then(checkStatus);
  },

  async getBatchStudents(payload, headers = {}) {
    return $backend
      .post("teachers_get_batch_students", payload)
      .then(checkStatus);
  },

  async getBatchMarks(payload, headers = {}) {
    return $backend.post("teachers_get_batch_marks", payload).then(checkStatus);
  },

  async saveTemplate(payload) {
    return await $backend
      .post("teachers_save_template", payload)
      .then(checkStatus);
  }
};
