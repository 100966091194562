var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between" },
        [
          _c("page-title", {
            attrs: { "page-title": _vm.title[_vm.$language][_vm.edit] },
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "black", icon: "", large: "" },
                        on: {
                          click: () => {
                            _vm.$router.push({
                              name: "grading",
                            })
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("arrow_back")])],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          !_vm.networkError
            ? _c("v-switch", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          !_vm.$language
                            ? _c("span", { attrs: { id: "label" } }, [
                                _vm._v(
                                  "If you Customize Your students certificate,"
                                ),
                                _c("br"),
                                _vm._v(
                                  "so Click to " +
                                    _vm._s(_vm.edit ? "Disable" : "Enable")
                                ),
                              ])
                            : _c("span", { attrs: { id: "label" } }, [
                                _vm._v(
                                  "আপনি যদি আপনার ছাত্রদের সার্টিফিকেট কাস্টমাইজ করতে চান,"
                                ),
                                _c("br"),
                                _vm._v(
                                  "তাহলে " +
                                    _vm._s(_vm.edit ? "ডিজেবল" : "এনেবল") +
                                    " করতে ক্লিক করুন"
                                ),
                              ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  152201969
                ),
                model: {
                  value: _vm.edit,
                  callback: function ($$v) {
                    _vm.edit = $$v
                  },
                  expression: "edit",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.networkError
        ? _c("no-content-component", {
            attrs: { message: _vm.networkErrorNoContentMessage },
          })
        : _c(
            "v-card",
            { staticClass: "pa-16" },
            [
              _c(
                "div",
                {
                  class: `d-flex ${
                    _vm.edit ? "justify-space-between" : "justify-center"
                  }`,
                },
                [
                  _vm.edit
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column justify-start" },
                        [
                          _c(
                            "v-form",
                            {
                              model: {
                                value: _vm.allValid,
                                callback: function ($$v) {
                                  _vm.allValid = $$v
                                },
                                expression: "allValid",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-file-input", {
                                    attrs: {
                                      accept:
                                        "image/png, image/jpeg, image/bmp, image/svg+xml",
                                      outlined: "",
                                      label: !_vm.$language
                                        ? "Upload your logo"
                                        : "আপনার লোগো আপলোড করুন",
                                      rules: _vm.rules.file,
                                      placeholder: !_vm.$language
                                        ? "Upload your file here..."
                                        : "এখানে আপনার ফাইল আপলোড করুন",
                                      "prepend-icon": null,
                                    },
                                    model: {
                                      value: _vm.logo,
                                      callback: function ($$v) {
                                        _vm.logo = $$v
                                      },
                                      expression: "logo",
                                    },
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      label: !_vm.$language
                                        ? "Description"
                                        : "বিবরণ",
                                      outlined: "",
                                      rules: _vm.descriptionRule,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateTemplate()
                                      },
                                    },
                                    model: {
                                      value: _vm.description,
                                      callback: function ($$v) {
                                        _vm.description = $$v
                                      },
                                      expression: "description",
                                    },
                                  }),
                                  _c("v-file-input", {
                                    attrs: {
                                      outlined: "",
                                      accept:
                                        "image/png, image/jpeg, image/bmp, image/svg+xml",
                                      rules: _vm.rules.file,
                                      label: !_vm.$language
                                        ? "Upload Your Digital Signature"
                                        : "আপনার ডিজিটাল স্বাক্ষর আপলোড করুন",
                                      placeholder: !_vm.$language
                                        ? "Upload your file here..."
                                        : "এখানে আপনার ফাইল আপলোড করুন",
                                      "prepend-icon": null,
                                    },
                                    model: {
                                      value: _vm.signature,
                                      callback: function ($$v) {
                                        _vm.signature = $$v
                                      },
                                      expression: "signature",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("iframe", {
                    ref: "frame",
                    staticClass: "frame",
                    attrs: { srcdoc: _vm.template },
                  }),
                ]
              ),
              _vm.edit
                ? _c(
                    "m-row",
                    { staticClass: "mt-8", attrs: { justify: "end" } },
                    [
                      _c(
                        "m-rounded-button",
                        {
                          staticClass: "mx-4",
                          attrs: { outlined: "", color: "red accent-3" },
                          on: {
                            click: function ($event) {
                              return _vm.reset()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("cancel"),
                          ]),
                          _vm._v(
                            _vm._s(!_vm.$language ? "Cancel" : "বাতিল করুন")
                          ),
                        ],
                        1
                      ),
                      _c(
                        "m-rounded-button",
                        {
                          attrs: {
                            depressed: "",
                            disabled: !_vm.allValid,
                            loading: _vm.saving,
                          },
                          on: { click: _vm.save },
                        },
                        [
                          _c("v-icon", [_vm._v("save_alt")]),
                          _vm._v(_vm._s(!_vm.$language ? "Save" : "সেভ করুন")),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }