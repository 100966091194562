<template>
  <v-container>
    <div class="d-flex justify-space-between">
      <page-title :page-title="title[$language][edit]">
        <template #left>
          <v-btn
            class="mr-2"
            color="black"
            icon
            large
            @click="
              () => {
                $router.push({
                  name: 'grading',
                });
              }
            "
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
        </template>
      </page-title>
      <v-switch v-model="edit" v-if="!networkError">
        <template v-slot:label>
          <span v-if="!$language" id="label"
            >If you Customize Your students certificate,<br />so Click to
            {{ edit ? "Disable" : "Enable" }}</span
          >
          <span v-else id="label"
            >আপনি যদি আপনার ছাত্রদের সার্টিফিকেট কাস্টমাইজ করতে চান,<br />তাহলে
            {{ edit ? "ডিজেবল" : "এনেবল" }} করতে ক্লিক করুন</span
          >
        </template>
      </v-switch>
    </div>
    <no-content-component
      v-if="networkError"
      :message="networkErrorNoContentMessage"
    ></no-content-component>
    <v-card v-else class="pa-16"
      ><div
        :class="`d-flex ${edit ? 'justify-space-between' : 'justify-center'}`"
      >
        <div v-if="edit" class="d-flex flex-column justify-start">
          <v-form v-model="allValid">
            <!-- <p>Upload your logo</p> -->
            <div>
              <v-file-input
                v-model="logo"
                accept="image/png, image/jpeg, image/bmp, image/svg+xml"
                outlined
                :label="
                  !$language ? 'Upload your logo' : 'আপনার লোগো আপলোড করুন'
                "
                :rules="rules.file"
                :placeholder="
                  !$language
                    ? 'Upload your file here...'
                    : 'এখানে আপনার ফাইল আপলোড করুন'
                "
                :prepend-icon="null"
              ></v-file-input>

              <v-textarea
                v-model="description"
                :label="!$language ? 'Description' : 'বিবরণ'"
                outlined
                :rules="descriptionRule"
                @change="updateTemplate()"
              ></v-textarea>
              <!-- <p>Upload Your Digital Signature</p> -->
              <v-file-input
                v-model="signature"
                outlined
                accept="image/png, image/jpeg, image/bmp, image/svg+xml"
                :rules="rules.file"
                :label="
                  !$language
                    ? 'Upload Your Digital Signature'
                    : 'আপনার ডিজিটাল স্বাক্ষর আপলোড করুন'
                "
                :placeholder="
                  !$language
                    ? 'Upload your file here...'
                    : 'এখানে আপনার ফাইল আপলোড করুন'
                "
                :prepend-icon="null"
              ></v-file-input>
            </div>
          </v-form>
        </div>
        <iframe ref="frame" class="frame" :srcdoc="template"></iframe>
      </div>
      <m-row v-if="edit" justify="end" class="mt-8">
        <m-rounded-button
          outlined
          color="red accent-3"
          class="mx-4"
          @click="reset()"
          ><v-icon left>cancel</v-icon
          >{{ !$language ? "Cancel" : "বাতিল করুন" }}</m-rounded-button
        >
        <m-rounded-button
          depressed
          :disabled="!allValid"
          :loading="saving"
          @click="save"
          ><v-icon>save_alt</v-icon
          >{{ !$language ? "Save" : "সেভ করুন" }}</m-rounded-button
        >
      </m-row>
    </v-card>
  </v-container>
</template>

<script>
import PageTitle from "~ecf/components/PageTitle";
import GradingService from "#ecf/grading/service";
import GradingApi from "#ecf/grading/api";
import { FieldValidations } from "/global/utils/validations.js";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "CertificateTemplate",
  components: { PageTitle, NoContentComponent },
  mixins: [FieldValidations],
  data() {
    return {
      networkError: false,
      edit: false,
      template: undefined,
      logo: undefined,
      signature: undefined,
      description: undefined,
      defaultDescription:
        "This is to certify that $student_name successfully completed online course of $course_name.",
      formattedLogo: undefined,
      formattedSignature: undefined,
      descriptionRule: [
        (v) =>
          (v.includes("$student_name") && v.includes("$course_name")) ||
          "Description must contain $student_name and $course_name",
      ],
      allValid: false,
      saving: false,
      rules: {
        file: [(v) => this.fileSizeLessThanOrEqual(v, 80 * 1024)],
      },
    };
  },
  watch: {
    logo() {
      if (this.logo && this.logo["size"] <= 80010) {
        var reader = new FileReader();
        reader.readAsDataURL(this.logo);
        reader.onload = () => {
          this.formattedLogo = reader.result;
          this.updateTemplate();
        };
        reader.onerror = (error) => {
          this.$root.$emit("alert", [undefined, error]);
        };
      } else {
        this.formattedLogo = undefined;
        this.updateTemplate(true);
      }
    },
    signature() {
      if (this.signature && this.signature["size"] <= 80010) {
        var reader = new FileReader();
        reader.readAsDataURL(this.signature);
        reader.onload = () => {
          this.formattedSignature = reader.result;
          this.updateTemplate();
        };
        reader.onerror = (error) => {
          this.$root.$emit("alert", [undefined, error]);
        };
      } else {
        this.formattedSignature = undefined;
        this.updateTemplate(true);
      }
    },
  },
  mounted() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.networkError = true;
      return;
    }
    try {
      this.template = GradingService.getTemplate();
      this.description = this.defaultDescription;
      /*let scale = this.$refs.frame.clientWidth / 1295;
      this.$refs.frame.style.transform = `scale(${scale})`;
      this.$refs.frame.style.width =
        this.$refs.frame.clientWidth / scale + "px";
      this.$refs.frame.style.transformOrigin = "0 0";*/
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message]);
    }
  },
  computed: {
    title() {
      return {
        true: {
          true: "সার্টিফিকেট কাস্টমাইজেশন",
          false: "সার্টিফিকেট",
        },
        false: {
          true: "Certificate Customization",
          false: "Certificate",
        },
      };
    },
    networkErrorNoContentMessage() {
      return !this.$language
        ? "Certificate tamplate is currently unavailable"
        : "বর্তমানে সার্টিফিকেট টেমপ্লেট এনে দেয়া সম্ভব হচ্ছে না";
    },
  },
  methods: {
    updateTemplate(shouldUpdate = this.allValid) {
      if (shouldUpdate) {
        this.template = GradingService.getTemplate(
          this.formattedLogo,
          this.description,
          this.formattedSignature
        );
      }
    },
    reset() {
      this.edit = false;
      this.description = this.defaultDescription;
      this.logo = undefined;
      this.signature = undefined;
      this.template = GradingService.getTemplate();
    },
    async save() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      try {
        this.saving = true;
        await GradingApi.saveTemplate({
          template: this.template,
          course_id: this.$route.params.courseId,
        });
        this.$router.go(-1);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style scoped>
#label {
  font-family: sans-serif;
  color: #3d3d3d;
}
.frame {
  width: 70%;
  height: 700px;
}
</style>
